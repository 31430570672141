import React from 'react';
import classNames from 'classnames';

import CustomButton from 'components/partials/custom-button/custom-button';
import MarkdownComponent from 'containers/user/learn/components/markdown/markdown';
import ErrorWidget from 'containers/user/home/components/widgets/error-widget';

import Icons from 'assets/icons';
import { formatDate } from 'helpers/dates-formatter';

export const getReleaseNotesConfig = (withImage) =>
  withImage
    ? {
        colSpan: 2,
        rowSpan: {
          default: 9,
          mobile: 14,
        },
      }
    : {
        colSpan: 1,
        rowSpan: {
          default: 9,
        },
      };

const SkeletonLoader = ({ withImage }) => (
  <div
    role="progressbar"
    className={classNames(
      'h-full w-full p-5 lg:py-3 bg-gray-100 rounded-lg animate-pulse flex',
      withImage ? 'flex-row gap-x-5' : 'flex-col'
    )}
  >
    {!withImage && <div className="h-full w-1/2 bg-gray-300 rounded"></div>}
    <div className="flex-1 flex flex-col gap-y-2">
      <div className="h-6 w-1/4 bg-gray-300 rounded mb-2"></div>
      <div className="h-4 w-3/4 bg-gray-300 rounded"></div>
      <div className="h-4 w-full bg-gray-300 rounded"></div>
      <div className="h-4 w-2/3 bg-gray-300 rounded"></div>
      <div className="mt-auto h-8 w-32 bg-gray-300 rounded"></div>
    </div>
  </div>
);

const ReleaseNotes = ({ data, loading, error, hasImage }) => {
  if (loading) {
    return <SkeletonLoader withImage={hasImage} />;
  }

  const {
    releaseNotesDate,
    image: releaseImage,
    releaseNotes,
  } = data?.attributes;

  if (error || !releaseNotes) {
    return (
      <div className="px-5 py-4 rounded-lg h-full w-full flex flex-col justify-center border border-gray-05">
        <h3 className="font-bold text-lg text-indigo-02 mb-2.5">
          Release Notes
        </h3>
        <ErrorWidget />
      </div>
    );
  }

  return (
    <div className="px-5 py-4 bg-primary-light rounded-lg h-full w-full flex flex-col justify-between">
      <div className="flex items-start flex-wrap">
        <div className="flex items-center justify-between w-full mb-2.5">
          <h3 className="font-bold text-lg text-indigo-02">Release Notes</h3>
          <p className="font-sm font-semibold text-blue-03">
            {formatDate(releaseNotesDate, 'MMMM D, YYYY')}
          </p>
        </div>

        {releaseImage && (
          <img
            src={releaseImage.imageSrc.data.attributes.url}
            alt="Release Notes"
            className=" rounded-md w-[285px] h-[194px] mr-4 mb-2"
          />
        )}

        <div className="flex-1">
          <div className="text-indigo-02">
            <MarkdownComponent text={releaseNotes} />
          </div>
        </div>
      </div>
      <CustomButton
        variant="inverse"
        customClass="self-start border-none focus:bg-indigo-02 focus:text-white"
        size="sm"
        onClick={() => console.log('View Details')}
        iconAfter={<Icons.DoubleArrow className="ml-2 w-2 h-2" />}
      >
        View Details
      </CustomButton>
    </div>
  );
};

export default ReleaseNotes;
