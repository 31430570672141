import React from 'react';
import Markdown from 'markdown-to-jsx';

const MarkdownComponent = ({ text = '' }) => (
  <div className="markdown">
    <Markdown
      options={{
        overrides: {
          a: {
            props: {
              className: 'text-blue-02 underline',
            },
          },
          h1: {
            props: {
              className: 'font-semibold',
            },
          },
          h2: {
            props: {
              className: 'font-semibold',
            },
          },
          h3: {
            props: {
              className: 'font-semibold',
            },
          },
          h4: {
            props: {
              className: 'font-semibold',
            },
          },
          h5: {
            props: {
              className: 'font-semibold',
            },
          },
          h6: {
            props: {
              className: 'font-semibold',
            },
          },
          strong: {
            props: {
              className: 'font-semibold',
            },
          },
        },
      }}
    >
      {text}
    </Markdown>
  </div>
);

export default MarkdownComponent;
