import { useState, useEffect } from 'react';

const breakpoints = [
  { name: 'mobile', width: 375 },
  { name: 'tablet', width: 780 },
  { name: 'xs', width: 960 },
  { name: 'sm', width: 1024 },
  { name: 'md', width: 1280 },
  { name: 'lg', width: 1440 },
  { name: 'xl', width: 1600 },
];

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('xl');

  useEffect(() => {
    const updateBreakpoint = () => {
      const width = window.innerWidth;

      const currentBreakpoint =
        breakpoints
          .slice()
          .reverse()
          .find((breakpoint) => width >= breakpoint.width)?.name || 'mobile';

      setBreakpoint(currentBreakpoint);
    };

    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);

    return () => window.removeEventListener('resize', updateBreakpoint);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
