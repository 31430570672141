import React from 'react';

import Icons from 'assets/icons';

const ErrorWidget = () => (
  <div className="flex flex-col items-center justify-center h-full w-full p-5 bg-gray-00 rounded-lg">
    <Icons.Warning customClass="h-7 w-7 mr-2 text-red-04 mb-2" />
    <p className="text-lg font-semibold">Something went wrong.</p>
    <p>Please try to reload the page.</p>
  </div>
);

export default ErrorWidget;
