import React, { useEffect, useState } from 'react';
import api from 'api';

const withWidgetData =
  (WrappedComponent) =>
  ({ userId, widgetId, ...props }) => {
    const [loading, setLoading] = useState(true);
    const [widgetData, setWidgetData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchWidgetData = async () => {
        try {
          const { data } = await api.getWidget({ userId, id: widgetId });
          const contentData = data.widget.content;

          setWidgetData(contentData);
        } catch (error) {
          setError(error);
          console.error('Error fetching widget data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchWidgetData();
    }, [userId, widgetId]);

    return (
      <WrappedComponent
        loading={loading}
        error={error}
        {...props}
        {...widgetData}
      />
    );
  };

export default withWidgetData;
