import CustomButton from 'components/partials/custom-button/custom-button';
import Icons from 'assets/icons';

export const NoToplineGoals = () => (
  <div className="flex-1 bg-gray-00 p-4 rounded-lg flex flex-col justify-center items-center">
    <p className="text-lg font-bold text-center mb-2">
      Your Topline Goals are not yet defined.
    </p>
    <p className="text-center">
      Please contact the MARKETview Success Team to set them up.
    </p>
  </div>
);

export const NoGoalsDefined = ({ onScheduleMeeting }) => (
  <div className="h-full bg-indigo-02 rounded-lg flex flex-col items-center justify-center text-center p-5 pt-0">
    <p className="text-white font-bold text-lg">
      Your goals are not yet defined.
    </p>

    <p className="text-white mb-5 leading-6">
      Please contact the MARKETview Success Team to set them up.
    </p>

    <CustomButton
      variant="primary"
      size="sm"
      onClick={onScheduleMeeting}
      iconAfter={<Icons.DoubleArrow className="ml-2 w-2 h-2" />}
    >
      Schedule a meeting
    </CustomButton>
  </div>
);

export const SkeletonLoader = () => (
  <div className="h-full bg-white border border-gray-200 rounded-lg flex flex-col p-5 animate-pulse">
    <div className="flex items-center gap-2 mb-4">
      <div className="h-5 w-5 bg-gray-300 rounded-full"></div>
      <div className="h-5 w-32 bg-gray-300 rounded"></div>
    </div>
    <div className="sr-only">Loading...</div>
    <div className="flex-1 bg-gray-50 p-4 rounded-lg flex items-center justify-center">
      <div className="h-8 w-16 bg-gray-300 rounded"></div>
    </div>
    <div className="h-8 w-24 bg-gray-300 rounded mt-4"></div>
  </div>
);
