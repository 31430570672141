import React from 'react';

// Import/export all the icons in this file
//admin panel pages
import { ReactComponent as Clients } from 'assets/icons/clients.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
//user panel pages
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Reports } from 'assets/icons/reports.svg';
import { ReactComponent as MyFiles } from 'assets/new-icons/my-files.svg';
//learn section
import { ReactComponent as BrokenLink } from 'assets/icons/broken-link.svg';
import { ReactComponent as Trophy } from 'assets/new-icons/trophy.svg';

//categories
import { ReactComponent as DataAnalysis } from 'assets/new-icons/learn-nav/categories/data-analisis.svg';
import { ReactComponent as Optimizing } from 'assets/new-icons/learn-nav/categories/optimazing.svg';
import { ReactComponent as AdvancedAnalytics } from 'assets/new-icons/learn-nav/categories/advanced-analitics.svg';
import { ReactComponent as DataVisualization } from 'assets/new-icons/learn-nav/categories/data-visualization.svg';
import { ReactComponent as Collaboration } from 'assets/new-icons/learn-nav/categories/collaboration.svg';
//content
import { ReactComponent as Articles } from 'assets/new-icons/learn-nav/content/articles.svg';
import { ReactComponent as Guides } from 'assets/new-icons/learn-nav/content/guides.svg';
import { ReactComponent as Downloads } from 'assets/new-icons/learn-nav/content/downloads.svg';
import { ReactComponent as Webinars } from 'assets/new-icons/learn-nav/content/webinars.svg';
import { ReactComponent as Glossary } from 'assets/new-icons/learn-nav/content/glossary.svg';
import { ReactComponent as Courses } from 'assets/new-icons/learn-nav/content/courses.svg';
import { ReactComponent as Like } from 'assets/new-icons/thumb-up.svg';
import { ReactComponent as QuoteMark } from 'assets/new-icons/quote-mark.svg';
import { ReactComponent as Calendar } from 'assets/new-icons/calendar.svg';
import { ReactComponent as OpenBook } from 'assets/new-icons/book-open.svg';
//notifications
import { ReactComponent as Info } from 'assets/new-icons/info.svg';
import { ReactComponent as Success } from 'assets/new-icons/success.svg';
import { ReactComponent as Warning } from 'assets/new-icons/warning.svg';
//actions
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Edit } from 'assets/new-icons/edit.svg';
import { ReactComponent as Delete } from 'assets/new-icons/delete.svg';
import { ReactComponent as Close } from 'assets/new-icons/close.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Upload } from 'assets/new-icons/upload.svg';
import { ReactComponent as Download } from 'assets/new-icons/download.svg';
import { ReactComponent as LogOut } from 'assets/new-icons/log-out.svg';
//labels
import { ReactComponent as Active } from 'assets/new-icons/active-status.svg';
import { ReactComponent as Expired } from 'assets/new-icons/expired-status.svg';
import { ReactComponent as Hidden } from 'assets/new-icons/hidden-status.svg';
import { ReactComponent as Archived } from 'assets/new-icons/archive.svg';
import { ReactComponent as Lock } from 'assets/new-icons/lock.svg';
import { ReactComponent as Link } from 'assets/new-icons/link.svg';
//images
import { ReactComponent as Logo } from 'assets/images/marketview-logo.svg';
import { ReactComponent as LoginLogo } from 'assets/images/mv-login-logo.svg';
import { ReactComponent as FullLogo } from 'assets/images/marketview-full-logo.svg';
import { ReactComponent as EmptyImageAll } from 'assets/images/empty-image-all.svg';
import { ReactComponent as EmptyImage } from 'assets/images/empty-image.svg';
import { ReactComponent as Security } from 'assets/images/security-hub.svg';
import { ReactComponent as GetStartedGuide } from 'assets/images/get-started-guide.svg';
//doc types
import { ReactComponent as DocFile } from 'assets/new-icons/file-types/doc-file.svg';
import { ReactComponent as PdfFile } from 'assets/new-icons/file-types/pdf-file.svg';
import { ReactComponent as PptFile } from 'assets/new-icons/file-types/ppt-file.svg';
import { ReactComponent as XlsFile } from 'assets/new-icons/file-types/xls-file.svg';
import { ReactComponent as DefaultFile } from 'assets/new-icons/file.svg';
//table icons
import { ReactComponent as Details } from 'assets/new-icons/details.svg';
import { ReactComponent as Eye } from 'assets/new-icons/eye.svg';
import { ReactComponent as CellInfo } from 'assets/new-icons/info-2.svg';
import { ReactComponent as DefaultSort } from 'assets/new-icons/table-header-icon.svg';
import { ReactComponent as Sort } from 'assets/new-icons/sort-up.svg';
//Goals
import { ReactComponent as ArrowUp } from 'assets/new-icons/goals/arrow-up-circle.svg';
import { ReactComponent as ArrowDown } from 'assets/new-icons/goals/arrow-down-circle.svg';
import { ReactComponent as PlusInCircle } from 'assets/new-icons/goals/plus-in-circle.svg';
import { ReactComponent as EditPencil } from 'assets/new-icons/goals/edit-pencil.svg';
import { ReactComponent as Maintain } from 'assets/new-icons/goals/maintain.svg';
import { ReactComponent as XinCircle } from 'assets/new-icons/goals/x-circle.svg';
//Widgets
import { ReactComponent as Target } from 'assets/new-icons/widgets/target.svg';

//other
import { ReactComponent as Arrow } from 'assets/icons/angle-right.svg';
import { ReactComponent as DoubleArrow } from 'assets/icons/double-angle-right.svg';
import { ReactComponent as Help } from 'assets/icons/help.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Spinner } from 'assets/icons/loading.svg';
import { ReactComponent as RequiredIndicator } from 'assets/new-icons/required-indicator.svg';
import { ReactComponent as Burger } from 'assets/new-icons/learn-nav/burger.svg';
import { ReactComponent as Check } from 'assets/icons/check 2.svg';

const icons = {
  //admin panel pages
  Clients,
  Users,
  Profile,
  //user panel pages
  Home,
  Reports,
  MyFiles,
  //learn section
  BrokenLink,
  Trophy,
  //categories
  DataAnalysis,
  Optimizing,
  AdvancedAnalytics,
  DataVisualization,
  Collaboration,
  //content
  Articles,
  Guides,
  Downloads,
  Webinars,
  Glossary,
  Courses,
  Like,
  QuoteMark,
  Calendar,
  OpenBook,
  //notifications
  Info,
  Success,
  Warning,
  //actions
  Search,
  Edit,
  Delete,
  Close,
  Plus,
  Upload,
  Download,
  LogOut,
  //labels
  Active,
  Expired,
  Hidden,
  Archived,
  Lock,
  Link,
  //images
  Logo,
  LoginLogo,
  FullLogo,
  EmptyImageAll,
  EmptyImage,
  Security,
  GetStartedGuide,
  //doc types
  DocFile,
  PdfFile,
  PptFile,
  XlsFile,
  DefaultFile,
  //table icons
  Details,
  Eye,
  CellInfo,
  DefaultSort,
  Sort,
  //Goals
  ArrowUp,
  ArrowDown,
  PlusInCircle,
  EditPencil,
  Maintain,
  XinCircle,
  //Widgets
  Target,
  //other
  Arrow,
  DoubleArrow,
  Help,
  Settings,
  Spinner,
  RequiredIndicator,
  Burger,
  Check,
};

// Wrap our icons in Boxes so we can use our color/space classes.
const boxedIcons = Object.keys(icons).reduce((result, icon) => {
  result[icon] = ({ customClass = '', onClick, ...rest }) => {
    const Icon = icons[icon];

    return (
      <Icon className={`block ${customClass}`} onClick={onClick} {...rest} />
    );
  };

  return result;
}, {});

export default boxedIcons;
