import { useState, useEffect } from 'react';
import { calculateColumnCount } from 'containers/user/home/components/masonry-grid/masonry-grid.helper';

/**
 * Custom hook to dynamically calculate and update the number of columns in a responsive grid layout
 * based on the container width and maximum number of columns.
 *
 * @param {Object} gridRef - A React ref object linked to the grid container DOM element.
 * @param {number} columnWidth - The width (in pixels) each column should occupy in the grid.
 * @param {number} maxColumns - The maximum number of columns allowed in the grid.
 * @returns {number} - The calculated number of columns based on the container width, capped by maxColumns.
 */
export const useResponsiveColumns = (gridRef, columnWidth, maxColumns) => {
  const [columnCount, setColumnCount] = useState(maxColumns);

  useEffect(() => {
    const updateColumnCount = (width) => {
      setColumnCount(calculateColumnCount(width, columnWidth, maxColumns));
    };

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length) {
        const { width } = entries[0].contentRect;

        updateColumnCount(width);
      }
    });

    if (gridRef.current) resizeObserver.observe(gridRef.current);

    return () => resizeObserver.disconnect();
  }, [gridRef, columnWidth, maxColumns]);

  return columnCount;
};
